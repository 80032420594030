<template>
  <div class="CommanderDamageTable">
    <Tile z="1" class="damageTable">
      <table>
        <tr>
          <th></th>
          <th class="heading-row" colspan="4"><font-awesome-icon :icon="['fas', 'shield']"/>&nbsp;DEALT TO&nbsp;<font-awesome-icon :icon="['fas', 'arrow-right']"/></th>
        </tr>
        <tr>
          <th class="heading-column" rowspan="6"><span><font-awesome-icon :icon="['fas', 'swords']" style="transform: rotate(90deg)"/><p style="padding-top:5px">DMG FROM</p><font-awesome-icon :icon="['fas', 'arrow-right']" style="transform: rotate(90deg)"/></span></th>
          <th style="background:black"></th>

          <th v-for="player in $store.state.game.game_obj.players" :key="player.publicId">
            <ProfilePic :icon="player.avatar"></ProfilePic>
          </th>
        </tr>
        <tr v-for="playerA in $store.state.game.game_obj.players" :key="playerA.publicId">
          <th>
            <ProfilePic :icon="playerA.avatar"></ProfilePic>
          </th>
          <!-- The commander damage cell -->
          <td v-for="playerB in $store.state.game.game_obj.players" :key="playerB.publicId" v-bind:class="{empty: playerA.publicId === playerB.publicId}">
            <span v-if="playerA.publicId === playerB.publicId"></span>
            <span v-if="playerA.publicId !== playerB.publicId">{{playerA.commanderDamage[playerB.publicId+"_0"].value}}</span>
            <span v-if="playerA.publicId !== playerB.publicId && playerA.commanderList.length === 2">/{{playerA.commanderDamage[playerB.publicId+"_1"].value}}</span>
          </td>
        </tr>
      </table>
    </Tile>
  </div>
</template>

<script>
  import Tile from "@/components/Tile.vue";
  import ProfilePic from "@/components/ProfilePic.vue";
  export default {
    name: 'CommanderDamage',
    components: {
      Tile,
      ProfilePic,
    },
  }
</script>

<style scoped lang="less">
.CommanderDamageTable {
  width: 100%;

  .damageTable {
    display: flex;

    justify-content: center;
  }

  table {
    //border-collapse: collapse;
    color: @font-color-primary;

    .heading {

      &-row {
        text-align: left;
        font-size: .75rem;
      }

      &-column {
        vertical-align: bottom;
        text-align: center;
        padding-bottom:10px;
        font-size: .75rem;
        span {
          width: 100%;
          -ms-writing-mode: tb-rl;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          white-space: nowrap;
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }

          i {
            transform: rotate(90deg);
          }
        }
        
      }
    }
  }

  th {
    padding-left: 5px;
  }

  td {
    &.empty {
      background: black;
    }
  }
}
</style>